import Banhcuon1 from "../../img/Banhcuon1.webp"
import Banhcuon2 from "../../img/Banhcuon2.png"
import Banhcuon3 from "../../img/Banhcuon3.png"
import Banhcuon4 from "../../img/Banhcuon5.webp"
import Banhcuon5 from "../../img/banhcuon6.webp"
import Cha from "../../img/Cha.webp"
import Cha2 from "../../img/Cha2.png"
import NuocCham1 from "../../img/Mam.png"
import NuocCham2 from "../../img/Mam2.png"
import NuocCham3 from "../../img/Mam3.png"
import NuocCham4 from "../../img/Mam4.webp"
import Cha1 from "../../img/Cha1.png"
import Hanh from "../../img/Hanh.webp"
import Hanh2 from "../../img/Hanh2.webp"
import Rau from "../../img/Rau.webp"
export default {
    1:[
        Banhcuon1,
        Banhcuon2,
        Banhcuon3,
        Banhcuon4,
        Banhcuon5,
    ],
    2:[
        Cha,
        Cha,
        Cha2,
        Cha1,
        Cha1,
    ],
    3:[
        NuocCham1,
        NuocCham2,
        NuocCham3,
        NuocCham4,
        NuocCham4,
    ],
    4:[
        Hanh,
        Hanh,
        Hanh2,
        Hanh,
        Hanh2,
    ],
    5:[
        Rau,
        Rau,
        Rau,
        Rau,
        Rau,
    ]
}