import React from 'react';
import BodyIntroduce from './BodyIntroduce';
const GioiThieu = () => {
    
    return (
        <BodyIntroduce></BodyIntroduce>
    );
}

export default GioiThieu;

